/**
 * Created by dpardell on 02/11/2022.
 */
angular
    .module('annexaApp')
    .component('annexaMergeThirds', {
        templateUrl: './components/thirds/annexa-merge-thirds/annexa-merge-thirds.html',
        controller: ['RestService', '$rootScope', '$filter', '$scope', 'DialogsFactory', 'CommonService', function(RestService, $rootScope,$filter, $scope, DialogsFactory, CommonService) {
            var vm = this;
			vm.third = undefined;
			$scope.$on('modifyThirdToMergeEvent', function(event, args) {
        	   if(args && args.item && args.item.third) {
            	    vm.data.third = args.item.third;
					if(vm.data.thirds && vm.data.thirds.length > 0){
						var ths = angular.copy(vm.data.thirds);
						vm.data.thirds.length = 0;
						_.forEach(ths, function(third){
							if(vm.data.third && vm.data.third.id && vm.data.third.id !== third.id){
								vm.data.thirds.push(third);
							}
						});
					}
               }
            });

			vm.onSelect = function(item){
				if(vm.data ){ 
					if(!vm.data.thirds){
						vm.data.thirds = [];
					}
					vm.data.thirds.push(item.third);
				}
				vm.third = undefined;
				
			}
			vm.loadFunction = function(value){
				if(!value) {
               		return [];
                }else if(value != '*' && value.length < 3) {
                    return [];
                }else{
	                if(value == '*') {
    	                value = '';
        	        }
                    var filter = { name: value};
					var added = [];
					if(vm.data){
						if(vm.data.third && vm.data.third.id){
							added.push(vm.data.third.id);
						}
						if(vm.data.thirds && vm.data.thirds.length > 0){
							_.forEach(vm.data.thirds, function(th){
								if(th.id && !$linq(added).contains(th.id)){
									added.push(th.id);
								}
							})
						}
					}
					var additional_filter = {added: added};
                                        
	                return CommonService.filterThirds(filter, additional_filter)
                    .then(function(data) {
                        var thirds = [];
                        if(data && data.length > 0) {
                            _.forEach(data, function(val) {
								if(!(vm.data && vm.data.third && vm.data.third.id && val.third && val.third.id === vm.data.third.id)){
                            		thirds.push({ 'id':  val.third.id, 'third': val.third, 'value': val.third.completeName });
								}
                            });
                        }
                        return thirds;
                    }).catch(function() {
                        return [];
                    });
				}
				
			}
			vm.deleteFunction = function(id){
				if(vm.data && vm.data.thirds && vm.data.thirds.length > 0){
					var index = $linq(vm.data.thirds).indexOf("x => x.id == "+id);
					if(index >= 0){
						vm.data.thirds.splice(index, 1);
					}
				}
			}
			this.$onInit = function() {
         	   	if(!vm.data){
         		   	vm.data = {
						thirds: [],
						third: undefined
					};
         	   	}
				
			}
        }],
        bindings: {
        	data: '=?'
        }
    })